<template>
    <div>
        <div v-for="item in stepContentList" :key="item.text">
            <p class="my-4" v-html="$t(`faqTab.gettingStarted.paypal.${item.text}`, { 'portal-link': brandConfig.portalLink })" />
            <img class="w-100" :src="item.image" :alt="$t(`faqTab.gettingStarted.paypal.${item.text}`)">
        </div>
        <p class="mt-4 mb-2" v-html="$t('faqTab.gettingStarted.paypal.provide')" />
        <p class="my-2" v-html="$t('faqTab.gettingStarted.paypal.providingEmail')" />
        <p class="mt-2" v-html="$t('faqTab.gettingStarted.paypal.contacted')" />
        <p class="mt-6" v-html="$t('faqTab.gettingStarted.paypal.once')" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['brandConfig']),
    },
    data() {
        return {
            stepContentList: [
                {
                    image: require("../assets/step7.4.png"),
                    text: "portalWebsite"
                },
                {
                    image: require("../assets/step7.5.png"),
                    text: "chatWill"
                }
            ]
        }
    }
}
</script>